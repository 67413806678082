
import Vue from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";

export default Vue.extend({
  name: "AppMenu",
  computed: {
    ...mapState("menu", ["pinned"]),
    ...mapGetters("auth", ["userRole"]),
    width(): number {
      return Number(process.env.VUE_APP_MENU_WIDTH);
    },
  },
  methods: {
    ...mapMutations("menu", ["setPinned"]),
  },
});
