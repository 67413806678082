
import Vue from "vue";
import AppHeader from "@/components/AppHeader.vue";
import AppMenu from "@/components/AppMenu.vue";
import { mapState, mapMutations, mapActions } from "vuex";

export default Vue.extend({
  name: "App",
  components: {
    AppHeader,
    AppMenu,
  },
  beforeCreate() {
    // Light theme
    this.$vuetify.theme.themes.light.primary =
      process.env.VUE_APP_THEME_LIGHT_PRIMARY;
    this.$vuetify.theme.themes.light.error =
      process.env.VUE_APP_THEME_LIGHT_ERROR;

    // Dark theme
    this.$vuetify.theme.themes.dark.primary =
      process.env.VUE_APP_THEME_DARK_PRIMARY;
    this.$vuetify.theme.themes.dark.error =
      process.env.VUE_APP_THEME_DARK_ERROR;
  },
  created() {
    this.loadUser().then(() => {
      if (this.authUser.role !== null) {
        if (
          this.$route.path === "/login" ||
          (this.$route.path === "/users" && this.authUser.role !== "ADMIN")
        )
          this.$router.replace({
            name: this.authUser.isEditor() ? "Provisioning" : "Devices",
          });
        else if (
          this.$route.path !== "/devices" &&
          this.authUser.role === "VIEWER"
        ) {
          this.$router.replace({ name: "Devices" });
        }
      } else if (this.$route.path !== "/login") {
        this.$router.push({ name: "Login" });
      }

      // Timeout is to avoid flickering
      setTimeout(() => this.startServing(), 100);
    });
  },
  computed: {
    ...mapState(["serving"]),
    ...mapState("menu", ["pinned"]),
    ...mapState("auth", ["authUser"]),
    mainStyle(): Record<string, unknown> {
      return this.authUser && this.authUser.isEditor()
        ? {
            "margin-left":
              (this.pinned ? process.env.VUE_APP_MENU_WIDTH : 56) + "px",
          }
        : {};
    },
  },
  methods: {
    ...mapMutations(["startServing"]),
    ...mapActions("auth", ["loadUser"]),
  },
});
