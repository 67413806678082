import { GetterTree } from "vuex";
import { AuthState } from "./types";
import { RootState } from "../types";

export const getters: GetterTree<AuthState, RootState> = {
  userRole: (state) => {
    const user = state.authUser;
    return user ? user.role : null;
  },
};
