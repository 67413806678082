import { ActionTree } from "vuex";
import { AuthState, AuthUser } from "./types";
import { RootState } from "../types";
import { auth, collections } from "@/firebase";
import {
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

export const actions: ActionTree<AuthState, RootState> = {
  async loadUser(context) {
    return new Promise((resolve) => {
      onAuthStateChanged(auth, async (user) => {
        let authUser: AuthUser | null = null;
        if (user && user.email) {
          const docRef = doc(collections.users, user.email);
          const docSnap = await getDoc(docRef);
          const data = docSnap.data();

          authUser = new AuthUser(
            user.displayName || user.email,
            user.email,
            data ? data.role : null,
            user.photoURL
          );
          context.dispatch("devices/loadModels", {}, { root: true });
        }
        context.commit("setUser", authUser);
        resolve(authUser);
      });
    });
  },
  async loginUser(context) {
    try {
      context.commit("setLoading", true, { root: true });
      if (!auth.currentUser) {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
      }
      await context.dispatch("loadUser");
    } catch (err) {
      alert("Oops, something went wrong!");
      console.log(err);
    } finally {
      context.commit("setLoading", false, { root: true });
    }
  },
  async logoutUser({ commit }) {
    try {
      commit("setLoading", true, { root: true });
      await auth.signOut();
      commit("setUser", null);
    } catch (err) {
      alert("Oops, something went wrong!");
      console.log(err);
    } finally {
      commit("setLoading", false, { root: true });
    }
  },
};
