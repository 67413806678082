
import LoginFlow from "@/mixins/login-flow";
import { mapState } from "vuex";

export default LoginFlow.extend({
  name: "AppHeader",
  data: () => ({
    title: process.env.VUE_APP_PROJECT_NAME,
  }),
  computed: {
    ...mapState(["loading"]),
  },
});
