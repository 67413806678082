import { User, Role } from "../users/types";

export class AuthUser extends User {
  name: string;
  picture: string | null;

  constructor(
    name: string,
    email: string,
    role: Role | null,
    picture: string | null
  ) {
    super(email, role);
    this.name = name;
    this.picture = picture;
  }

  isViewer(): boolean {
    return this.role === "VIEWER" || this.isEditor();
  }

  isEditor(): boolean {
    return this.role === "EDITOR" || this.isAdmin();
  }

  isAdmin(): boolean {
    return this.role === "ADMIN";
  }
}

export interface AuthState {
  authUser: AuthUser | null;
}
