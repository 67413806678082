import { Module } from "vuex";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { UsersState } from "./types";
import { RootState } from "../types";

const namespaced = true;

export const state: UsersState = {
  users: [],
};

export const users: Module<UsersState, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
};
