import { MutationTree } from "vuex";
import { UsersState, User } from "./types";

export const mutations: MutationTree<UsersState> = {
  setUsers(state, users: User[]) {
    state.users = users.filter((u) => u.role !== null);
  },
  addUser(state, user: User) {
    state.users = state.users.filter(
      (u) => u.email.toLowerCase() !== user.email.toLowerCase()
    );
    if (user.role !== null) {
      state.users.push(user);
    }
  },
  removeUser(state, email: string) {
    state.users = state.users.filter(
      (u) => u.email.toLowerCase() !== email.toLowerCase()
    );
  },
};
