import { VApp } from 'vuetify/lib/components/VApp';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,[_c('app-header'),(_vm.authUser && _vm.authUser.isEditor())?_c('app-menu'):_vm._e(),_c(VMain,{staticClass:"grey lighten-5",style:(_vm.mainStyle)},[(_vm.serving)?_c(VContainer,{staticClass:"pt-6"},[_c(VRow,{attrs:{"no-gutters":"","justify":"center"}},[_c(VCol,[_c('router-view')],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }