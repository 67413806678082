import { MutationTree } from "vuex";
import { DevicesState, Model, PreProvisionedDevice } from "./types";

export const mutations: MutationTree<DevicesState> = {
  setDomains(state, domains: string[]) {
    domains.sort((domain1, domain2) => {
      const d1 = domain1.toUpperCase();
      const d2 = domain2.toUpperCase();
      return d1 > d2 ? 1 : d1 < d2 ? -1 : 0;
    });
    state.domains = domains;
    state.customerId = "";
    state.tokens = [];
    state.devices = [];
  },
  setTokens(state, input: { customerId: string; tokens: string[] }) {
    input.tokens.sort((token1, token2) => {
      const t1 = token1.toUpperCase();
      const t2 = token2.toUpperCase();
      return t1 > t2 ? 1 : t1 < t2 ? -1 : 0;
    });
    state.customerId = input.customerId;
    state.tokens = input.tokens;
    state.devices = [];
  },
  setDevices(state, devices: PreProvisionedDevice[]) {
    state.devices = devices;
  },
  setModels(state, models: Model[]) {
    models.sort((model1, model2) => {
      const m1 = model1.hardwareModel.toUpperCase();
      const m2 = model2.hardwareModel.toUpperCase();
      return m1 > m2 ? 1 : m1 < m2 ? -1 : 0;
    });
    state.models = models;
  },
};
