import { Module } from "vuex";
import { mutations } from "./mutations";
import { MenuState } from "./types";
import { RootState } from "../types";

const namespaced = true;

export const state: MenuState = {
  pinned: localStorage.pinnedMenu === "true",
};

export const menu: Module<MenuState, RootState> = {
  namespaced,
  state,
  mutations,
};
