import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import { Role } from "@/store/users/types";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/provisioning",
    name: "Provisioning",
    component: () => import("../views/Provisioning.vue"),
    meta: { authLevel: "EDITOR" },
  },
  {
    path: "/devices",
    name: "Devices",
    component: () => import("../views/Devices.vue"),
    meta: { authLevel: "VIEWER" },
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("../views/Users.vue"),
    meta: { authLevel: "ADMIN" },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "*",
    redirect: "/provisioning",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function isAdmin(role?: Role): boolean {
  return role === "ADMIN";
}

function isEditor(role?: Role): boolean {
  return role === "EDITOR" || isAdmin(role);
}

router.beforeEach((to, from, next) => {
  if (store.state.serving) {
    const authLevel = to.meta?.authLevel;
    const userRole = store.getters["auth/userRole"];
    if (userRole === null && authLevel) {
      return next("/login");
    } else if (userRole === "VIEWER" && isEditor(authLevel)) {
      return next("/devices");
    } else if (userRole !== "ADMIN" && isAdmin(authLevel)) {
      return next("/provisioning");
    }
  }
  next();
});

export default router;
