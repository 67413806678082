import Vue from "vue";
import Vuex, { MutationTree } from "vuex";
import { RootState } from "./types";
import { auth } from "./auth";
import { devices } from "./devices";
import { users } from "./users";
import { menu } from "./menu";

Vue.use(Vuex);

const state: RootState = {
  loading: true,
  serving: false,
};

const mutations: MutationTree<RootState> = {
  startServing(state) {
    state.serving = true;
    state.loading = false;
  },
  setLoading(state, loading: boolean) {
    state.loading = loading;
  },
};

export default new Vuex.Store<RootState>({
  state,
  mutations,
  modules: {
    auth,
    devices,
    users,
    menu,
  },
});
