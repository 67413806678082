import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"app-menu"},[_c(VNavigationDrawer,{attrs:{"permanent":"","fixed":"","expand-on-hover":!_vm.pinned,"width":_vm.width,"dense":""}},[_c(VList,{attrs:{"nav":""}},[_c(VListItem,{attrs:{"link":"","to":{ name: 'Provisioning' },"disabled":_vm.userRole === 'VIEWER'}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-google-chrome")])],1),_c(VListItemTitle,[_vm._v("Provisioning")])],1),_c(VListItem,{attrs:{"link":"","to":{ name: 'Devices' }}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-laptop")])],1),_c(VListItemTitle,[_vm._v("Devices")])],1),(_vm.userRole === 'ADMIN')?_c(VListItem,{attrs:{"link":"","to":{ name: 'Users' }}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-account-multiple")])],1),_c(VListItemTitle,[_vm._v("Users")])],1):_vm._e()],1),_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"menu-resize",class:{ pinned: _vm.pinned },attrs:{"text":"","small":"","fab":""},on:{"click":function($event){return _vm.setPinned(!_vm.pinned)}}},on),[_c(VIcon,{attrs:{"color":"grey darken-1"}},[_vm._v(_vm._s(_vm.pinned ? "mdi-chevron-left" : "mdi-chevron-right"))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.pinned ? "Minimize menu" : "Maximize menu"))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }