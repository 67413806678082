import Vue from "vue";
import { mapState, mapActions } from "vuex";

export default Vue.extend({
  computed: {
    ...mapState("auth", ["authUser"]),
  },
  methods: {
    ...mapActions("auth", ["loginUser", "logoutUser"]),
    login(): void {
      this.loginUser().then(() => {
        if (this.authUser.isViewer()) {
          this.$router.replace({
            name: this.authUser.isEditor() ? "Provisioning" : "Devices",
          });
        }
      });
    },
    logout(): void {
      this.logoutUser().then(() => {
        if (this.$route.path !== "/login") {
          this.$router.push({ name: "Login" });
        }
      });
    },
  },
});
