import { ActionTree } from "vuex";
import { UsersState, User } from "./types";
import { RootState } from "../types";
import { collections } from "@/firebase";
import { deleteDoc, doc, getDocs, setDoc } from "firebase/firestore";

export const actions: ActionTree<UsersState, RootState> = {
  async loadUsers({ commit }) {
    try {
      commit("setLoading", true, { root: true });
      const users: User[] = [];
      const snapshot = await getDocs(collections.users);
      snapshot.forEach((doc) => {
        users.push(new User(doc.id, doc.data().role));
      });
      commit("setUsers", users);
    } catch (err) {
      alert("Oops! Something went wong");
      console.log(err);
    } finally {
      commit("setLoading", false, { root: true });
    }
  },
  async saveUser({ commit }, user: User) {
    try {
      commit("setLoading", true, { root: true });
      await setDoc(doc(collections.users, user.email), { role: user.role });
      commit("addUser", user);
    } catch (err) {
      alert("Oops! Something went wong");
      console.log(err);
    } finally {
      commit("setLoading", false, { root: true });
    }
  },
  async removeUser({ commit }, email: string) {
    try {
      commit("setLoading", true, { root: true });
      await deleteDoc(doc(collections.users, email));
      commit("removeUser", email);
    } catch (err) {
      alert("Oops! Something went wong");
      console.log(err);
    } finally {
      commit("setLoading", false, { root: true });
    }
  },
};
