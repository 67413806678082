import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  connectFirestoreEmulator,
  collection,
} from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import * as settings from "../firebase.json";

// firebase configuration
const config = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: `${process.env.VUE_APP_PROJECT_ID}.appspot.com`,
  messagingSenderId: "496437051216",
  appId: "1:496437051216:web:87af9ffbcd64ee72f33655",
};

// firebase init and modules
const firebase = initializeApp(config);
const auth = getAuth(firebase);
const firestore = getFirestore(firebase);
const functions = getFunctions(firebase, "europe-west1");

if (process.env.NODE_ENV === "development") {
  connectFirestoreEmulator(
    firestore,
    "localhost",
    settings.emulators.firestore.port
  );
  connectFunctionsEmulator(
    functions,
    "localhost",
    settings.emulators.functions.port
  );
}

// firebase collections
const collections = {
  domains: collection(firestore, "domains"),
  tokens: collection(firestore, "tokens"),
  users: collection(firestore, "users"),
};

export { auth, firestore, functions, collections };
