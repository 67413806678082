import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarTitle } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"app-header"},[_c(VAppBar,{attrs:{"app":"","color":"primary","dark":"","short":"","extended":"","extension-height":"0"}},[_c('div',{staticClass:"header-logo"},[_c(VImg,{attrs:{"src":require("@/assets/logo.svg"),"width":"44","contain":""}})],1),_c(VAppBarTitle,[_vm._v(_vm._s(_vm.title))]),_c(VSpacer),_c(VMenu,{attrs:{"offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"fab":"","light":"","width":"44","height":"44","text":""}},on),[_c(VAvatar,{staticClass:"profile-button-picture",attrs:{"size":"44"}},[_c(VImg,{attrs:{"src":_vm.authUser ? _vm.authUser.picture : require('@/assets/user.svg'),"contain":""}})],1)],1)]}}])},[(_vm.authUser)?_c(VList,[_c(VListItem,[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-account-circle")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.authUser.name))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.authUser.email))])],1)],1),_c(VDivider),_c(VListItem,{attrs:{"link":"","dense":""},on:{"click":function($event){return _vm.logout()}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-logout-variant")])],1),_c(VListItemTitle,[_vm._v("Sign out")])],1)],1):_c(VList,[_c(VListItem,{attrs:{"link":"","dense":""},on:{"click":function($event){return _vm.login()}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-login-variant")])],1),_c(VListItemTitle,[_vm._v("Sign in")])],1)],1)],1),(_vm.loading)?_c(VProgressLinear,{staticClass:"header-loader",attrs:{"slot":"extension","indeterminate":"","color":"primary"},slot:"extension"}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }