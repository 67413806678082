import { ActionTree } from "vuex";
import {
  DevicesState,
  Model,
  Device,
  DeviceWithToken,
  PreProvisionedDevice,
  Token,
} from "./types";
import { RootState } from "../types";
import { functions, collections } from "@/firebase";
import { doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

export const actions: ActionTree<DevicesState, RootState> = {
  async loadDomains({ commit }) {
    const domains: string[] = [];
    commit("setDomains", domains); // Make empty
    const snapshot = await getDocs(collections.domains);
    snapshot.forEach((doc) => {
      domains.push(doc.id);
    });
    commit("setDomains", domains);
  },
  async loadTokens({ commit }, domain: string) {
    commit("setTokens", { customerId: "", tokens: [] }); // Make empty
    const snapshot = await getDoc(doc(collections.domains, domain));
    commit("setTokens", snapshot.data());
  },
  async loadModels({ state, commit }) {
    if (!state.models) {
      const result = await httpsCallable<void, Model[]>(functions, "models")();
      commit("setModels", result.data);
    }
  },
  async preProvisionDevices(
    { commit },
    input: { domain: string; token: Token; devices: Device[] }
  ) {
    try {
      commit("setLoading", true, { root: true });

      const data = {
        customerId: input.token.customerId,
        tokens: [input.token.devicePreProvisioningToken],
      };
      const snapshot = await getDoc(doc(collections.domains, input.domain));
      if (snapshot.exists()) {
        if (
          snapshot.data().customerId &&
          snapshot.data().customerId !== input.token.customerId
        ) {
          throw { error: "Wrong customer Id" };
        }
        data.tokens = snapshot.data().tokens;
        if (
          data.tokens.indexOf(input.token.devicePreProvisioningToken) === -1
        ) {
          data.tokens.push(input.token.devicePreProvisioningToken);
        }
      }

      // Pre-provision devices in chunks as promises
      const promises: Promise<PreProvisionedDevice[]>[] = [];
      input.devices
        .reduce((acc: DeviceWithToken[][], cur, i) => {
          const device = { ...cur, ...input.token };
          if (i % 100 === 0) {
            acc.push([device]);
          } else {
            acc[acc.length - 1].push(device);
          }
          return acc;
        }, [])
        .forEach((chunk: DeviceWithToken[]) => {
          promises.push(
            new Promise((resolve, reject) => {
              httpsCallable<DeviceWithToken[], PreProvisionedDevice[]>(
                functions,
                "provision"
              )(chunk)
                .then((result) => {
                  console.log(result.data);
                  resolve(result.data);
                })
                .catch((err) => {
                  console.log("ERROR");
                  console.log(err);
                  reject(err);
                });
            })
          );
        });

      // Add token info from firestore
      await setDoc(doc(collections.domains, input.domain), data);

      // Await all promises
      await Promise.all(promises);
    } finally {
      commit("setLoading", false, { root: true });
    }
  },
  async listPreProvisionedDevices({ commit }, preProvisioningToken: string) {
    try {
      commit("setLoading", true, { root: true });
      commit("setDevices", []); // Make empty
      const { data } = await httpsCallable<string, PreProvisionedDevice[]>(
        functions,
        "devices"
      )(preProvisioningToken);
      commit("setDevices", data);
    } finally {
      commit("setLoading", false, { root: true });
    }
  },
  async deletePreProvisionedDevice(
    { commit, state },
    preProvisionedDeviceId: string
  ) {
    try {
      commit("setLoading", true, { root: true });
      await httpsCallable<string, PreProvisionedDevice[]>(
        functions,
        "delete"
      )(preProvisionedDeviceId);
      const devices = state.devices.filter(
        (d) => d.preProvisionedDeviceId !== preProvisionedDeviceId
      );
      commit("setDevices", devices);
    } finally {
      commit("setLoading", false, { root: true });
    }
  },
  async validatePreProvisioningToken({ commit }, token: Token) {
    try {
      commit("setLoading", true, { root: true });
      await httpsCallable<Token, void>(functions, "validate")(token);
    } finally {
      commit("setLoading", false, { root: true });
    }
  },
};
