export type Role = "VIEWER" | "EDITOR" | "ADMIN";

export class User {
  email: string;
  role: Role | null;

  constructor(email: string, role: Role | null) {
    this.email = email;
    this.role = role;
  }
}

export interface UsersState {
  users: User[];
}
